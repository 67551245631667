import Img from "gatsby-image"
import React, { useState } from "react"
import { Box, Grid, Divider, Button } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import dpcgclogo from "../images/dpcgc/dpcgclogo.svg"
import newicon from "../images/dpcgc/newicon.svg"
import { Link } from "gatsby"
import HtmlToReact, { Parser as HtmlToReactParser } from "html-to-react"
import BgImage from "../components/dpcgc/BgImage"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import Paper from "@material-ui/core/Paper"
import VisibilityIcon from "@material-ui/icons/Visibility"

const isValidNode = function () {
  return true
}

const useStyles = makeStyles(theme => ({
  rooot: {
    width: "100%",
    background: "#243974",
    // height: "22rem",
    padding: "8.5rem 0 2rem 0",
  },
  dpcgclogo: {
    height: "11rem",
  },
  iamailogo: {
    height: "4rem",
    margin: "2rem",
  },
  nav: {
    color: "#243974",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  grid: {
    margin: "10px 40px",
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "0px 10px",
    },
  },
  othernav: {
    color: "#4F4F4F",
    fontSize: "14px",
    lineHeight: "16px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "14px",
    },
  },
  blueheading: {
    fontSize: "26px",
    lineHeight: "30px",
    color: "#243974",
  },
  date: {
    fontSize: "16px",
    lineHeight: "19px",
    color: "#FFFFFF",
    padding: "1rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  blackHeading: {
    color: "#000000",
    fontSize: "16px",
    lineHeight: "19px",
    padding: "1rem 2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "12px",
    },
  },
  eventDate: {
    fontSize: "16px",
    lineHeight: "20px",
    color: "#000000",
    fontWeight: "500",
    textTransform: "uppercase",
  },
  eventImage: {
    // height: "40rem",
    height: "100%",
    width: "100%",
    objectFit: "contain",
    [theme.breakpoints.down("sm")]: {
      // height: "50rem",
    },
  },
  eventTitle: {
    fontSize: "3rem",
  },
  eventContent: {
    fontSize: "16px",
    lineHeight: "20px",
  },
  back: {
    color: "#BDBDBD",
    fontSize: "16px",
    lineHeight: "19px",
    textTransform: "capitalize",
    fontStyle: "normal",
    fontWeight: "normal",
  },
  newicon: {
    height: "16px",
    [theme.breakpoints.down("md")]: {
      height: "14px",
    },
    [theme.breakpoints.down("sm")]: {
      height: "12px",
    },
    [theme.breakpoints.down("xs")]: {
      height: "10px",
    },
  },
  paper: {
    padding: "0.5rem 1rem",
    //cursor: "pointer",
    width: "100%",

    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    border: "none",
    fontWeight: "bold",
    fontSize: "1.5rem",
    lineHeight: "3.6rem",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      lineHeight: "2.8rem",
    },
  },
  head: {
    FontFace: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "22px",
    display: "flex",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#333333",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
  },
  value: {
    color: "#333333",

    fontSize: "14px",
    lineHeight: "22px",
    display: "flex",
    marginTop: "5px",
    textAlign:'left',
    [theme.breakpoints.down("md")]: {
      fontSize: "14px",
      lineHeight: "22px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      lineHeight: "22px",
    },
  },
  contentContainer: {
    // display: "flex",
    justifyContent: "center",
    textTransform: "capitalize",
  },
  background: {
    background: "#F2F2F2",
    minHeight: "90rem",
  },
}))

function GovtNotification({ data }) {
  const classes = useStyles()

  console.log("data-", data)

  const [showEvent, setShowEvent] = useState(false)
  const [eventid, setEventid] = useState()
  const [eventData, setEventData] = useState()

  console.log(showEvent)
  console.log(eventid)

  const htmlToReactParser = new HtmlToReactParser()
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
  const preProcessingInstructions = [
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return node.name === "img"
      },
      preprocessNode: function (node, children) {
        return (node.attribs.src = `https://vc.webnyay.in:5000${node.attribs.src}`)
      },
    },
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return (
          node.parent && node.parent.name && node.parent.name === "blockquote"
        )
      },
      preprocessNode: function (node, children) {
        return (node.children[0].data = `"${node.children[0].data}"`)
      },
    },
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return node.name === "table"
      },
      preprocessNode: function (node, children) {
        return (node.attribs = { id: "table" })
      },
    },
  ]
  const processingInstructions = [
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]

  return (
    <>
      <Box className={classes.rooot}>
        <Grid container justifyContent="center">
          <Grid item>
            <Link to="/">
              <img
                src={dpcgclogo}
                alt="dpcgc logo"
                className={classes.dpcgclogo}
              />
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box className={classes.background}>
        <Grid container spacing={0} justifyContent="center">
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/whoweare" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>ABOUT US</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/grbmembers" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>GRB MEMBERS</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/govtnotification" style={{ textDecoration: "none" }}>
              <Typography className={classes.nav}>
                GOVT. NOTIFICATIONS
              </Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/discolsures" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>DISCLOSURES</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/faqs" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>FAQS</Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            <Link to="/leadersspeak" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>
                LEADERSHIP TEAM
              </Typography>
            </Link>
          </Grid>
          <Grid item className={classes.grid}>
            <Box height="16px" />
            {/* <Box style={{ textAlign: "right" }}>
              <img src={newicon} alt="new" className={classes.newicon} />
          </Box> */}
            <Link to="/consumerresources" style={{ textDecoration: "none" }}>
              <Typography className={classes.othernav}>
                CONSUMER RESOURCES
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Box height="7rem" />

        <Grid container justifyContent="center">
          <Grid item md={8} sm={9} xs={10}>
            <Typography className={classes.blueheading}>
              Government Notifications
            </Typography>
          </Grid>
        </Grid>
        <Box height="5rem"></Box>
        {/* <Grid container justifyContent="center" spacing={2}>
          <Grid item sm={4} xs={4} md={5}>
            <Typography className={classes.head}>Title</Typography>
          </Grid>
          <Grid item sm={2} xs={2} md={2}>
            <Typography className={classes.head}>Date</Typography>
          </Grid>
        </Grid> */}
        <Grid container justifyContent="center">
          <Grid item md={8} sm={9} xs={10}>
            <Grid container>
              <Paper
                className={classes.paper}
                style={{ background: "none", boxShadow: "none" }}
              >
                <Grid container justifyContent="center">
                  <Grid item sm={8} xs={7} md={8}>
                    <Box className={classes.contentContainer}>
                      <Typography className={classes.head}>Title</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={2} xs={3} md={2}>
                    <Box className={classes.contentContainer}>
                      <Typography className={classes.head}>Date</Typography>
                    </Box>
                  </Grid>
                  <Grid item sm={2} xs={2} md={2}></Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item md={8} sm={9} xs={10}>
            <Grid container>
              {data.allStrapiGovtnotification.nodes.map(node => {
                let date = node.published_at
                let res = ""

                try {
                  let arr = date.split("-")
                  if (date) res = arr[2] + "-" + arr[1] + "-" + arr[0]
                } catch {}
                if(node.title != 'hide')
                return (
                  <>
                    <Paper className={classes.paper}>
                      <Grid container justifyContent="center">
                        <Grid item sm={8} xs={7} md={8}>
                          {/* <Box className={classes.contentContainer}> */}
                            <Typography className={classes.value}>
                              {node.title}
                            </Typography>
                          {/* </Box> */}
                        </Grid>
                        <Grid item sm={2} xs={3} md={2}>
                          {/* <Box className={classes.contentContainer}> */}
                            <Typography className={classes.value}>
                              {node.published_at}
                            </Typography>
                          {/* </Box> */}
                        </Grid>
                        <Grid item sm={2} xs={2} md={2} style={{textAlign:"center",lineHeight:"22px", margin:"5px 0 0"}}>
                          {/* <Box className={classes.contentContainer}> */}
                            <a
                              href={
                                node.Pdf == null ? null : node.Pdf.publicURL
                              }
                              target="_blank"
                              style={{ textDecoration: "none" }}
                            >
                              <Button style={{padding:'0',minWidth:'10px'}}><VisibilityIcon style={{fontSize:"14px"}}/></Button>
                            </a>
                          {/* </Box> */}
                        </Grid>
                      </Grid>
                    </Paper>
                  </>
                )
              })}
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box height="5rem" style={{background: "#F2F2F2"}} />

    </>
  )
}

export default GovtNotification

export const pageQuery = graphql`
  query govtnotification {
    allStrapiGovtnotification(sort: { fields: published_at, order: DESC }) {
      nodes {
        image {
          publicURL
          childImageSharp {
            fixed(width: 300, height: 200) {
              ...GatsbyImageSharpFixed
            }
            fluid(maxWidth: 1000, maxHeight: 1000, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Pdf {
          publicURL
        }
        id
        strapiId
        title
        published_at
      }
    }
  }
`

function createMarkup(text) {
  return { __html: text }
}
